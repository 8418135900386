<template>
  <div class="base_div">
    <div class="top_div">
      <p class="sh_title">中国·上海港</p>
      <p class="sh_simple_title">预配舱单发送平台—操作简单·回执快速·全年可发</p>
      <!--      <p class="sh_simple_title2">操作简单·回执快速·全年可发</p>-->
    </div>
    <div class="content_div">
      <div class="left_div">
        <div class="apply_div">
          <el-form :label-position="labelPosition" label-width="80px" :model="applyForm" :rules="rules" ref="applyForm">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="applyForm.phone" maxlength="11" minlength="11"></el-input>
            </el-form-item>
            <el-form-item label="企业名称" prop="companyName">
              <el-input v-model="applyForm.companyName"></el-input>
            </el-form-item>
          </el-form>
          <el-button :loading="loading" class="sh_apply_btn" @click="apply">立即开通</el-button>
          <div class="agreement_div">
            <span>开通即同意</span>
            <ManifestAgreementPopup/>
          </div>
        </div>
        <div class="wx_div">
          <div class="wx_img_div">
            <el-image :src="wx_img_1"></el-image>
            <div class="phone_div">
              <i class="el-icon-phone-outline"></i>
              <span>18644079547</span>
            </div>
          </div>
          <div class="wx_img_div">
            <el-image :src="wx_img_2"></el-image>
            <div class="phone_div">
              <i class="el-icon-phone-outline"></i>
              <span>13157331003</span>
            </div>
          </div>
        </div>
        <div class="consultBtn">
          扫码立即咨询
        </div>
      </div>
      <div class="right_div">
        <div v-html="content" class="content_html"></div>
        <!--        <el-image :src="sh_manifest_img" class="sh_manifest_img"></el-image>-->
      </div>
    </div>
    <sendCompany-dialog
        ref="sendCompanyDialog"
    ></sendCompany-dialog>
  </div>
</template>

<script>
import sh_manifest_img from "@/assets/img/manifest/sh_manifest_bg.jpg"
import wx_img_1 from "@/assets/img/manifest/xcm_wx_qrcode.jpg"
import wx_img_2 from "@/assets/img/manifest/nkc_wx_qrcode.jpg"
import bgImg from "@/assets/img/mainPageImg/productBg.png"
import {businessApply} from "@/api/businessApply";
import store from "@/store";
import ManifestAgreementPopup from "@/views/ShanghaiManifest/components/ManifestAgreementPopup.vue";
import {getNoticeById} from "@/api/sysMsg";
import sendCompanyDialog from "@/views/user/components/common/sendCompany.vue";

export default {
  name: "ShManifestAgencyPage",
  components: {sendCompanyDialog, ManifestAgreementPopup},
  data() {
    return {
      sh_manifest_img: sh_manifest_img,
      wx_img_1: wx_img_1,
      wx_img_2: wx_img_2,
      content: '',
      bgImg: bgImg,
      labelPosition: 'left',
      loading: false,
      applyForm: {
        phone: '',
        companyName: '',
      },
      rules: {
        phone: [
          {required: true, message: '请输入手机号', trigger: 'change'},
          {min: 11, max: 11, message: '请输入11位手机号', trigger: 'blur'}
        ],
        companyName: [
          {required: true, message: '请输入企业名称', trigger: 'change'},
          {min: 3, message: '请输入企业名称', trigger: 'blur'}
        ]
      }
    }
  },
  mounted() {
    this.getNoticeMsg();
  },
  methods: {
    getNoticeMsg() {
      getNoticeById({id: 82}).then((res) => {
        if (res.data.code === 200 && res.data.data.status === 'T') {
          this.content = res.data.data.contents;
        }
      })
    },
    apply() {
      this.$refs.applyForm.validate((valid) => {
        if (valid) {
          this.submitInfo();
        } else {
          return false;
        }
      });
    },
    showEntDialog() {
      this.$nextTick(() => {
        this.$refs.sendCompanyDialog.initByBusiness(1, this.applyForm);
      });
    },
    submitInfo() {
      const data = {
        id: store.state.user.id,
        ...this.applyForm
      }
      this.loading = true;
      businessApply(data).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.message)
          const type = JSON.parse(res.data.data);
          if (type.type === 1) {//手机号没有对应企业，需要继续上传营业执照之后进行企业审核
            this.showEntDialog();
          }
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    resetForm() {
      this.$refs.applyForm.resetFields();
    }
  }
}
</script>


<style scoped lang="stylus">
.base_div {
  background-image: url('../../../assets/img/mainPageImg/f1Order_bg.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
}

.top_div {
  padding: 3rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content_div {
  display: flex;
}

.apply_div {
  background: var(--GRAY-f1f3f6);
  padding: 3rem 5rem 1rem;
  box-shadow: 2px 0 9px 0 #25549b66;
}

.left_div {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.right_div {
  width: 60%;
  display: flex;
}

.sh_title {
  text-align: center;
  font-size: 48px;
  margin: 0;
  letter-spacing: 4px;
  line-height: 1.5;
  color: var(--RED-C11C20);
}

.sh_apply_btn {
  width: 100%;
  text-align: center;
  cursor: pointer;
  //width: fit-content;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  margin: 0;
  color: white;
  font-size: 20px;
  letter-spacing: 2px;
  border-radius: 4px;
  background: var(--RED-C11C20);
}

.sh_simple_title {
  text-align: center;
  letter-spacing: 4px;
  margin: 1rem 0;
  line-height: 1;
  font-size: 32px;
  color: var(--BLACK-333);
}

.sh_simple_title2 {
  text-align: center;
  letter-spacing: 4px;
  margin: 1rem 0;
  line-height: 1;
  font-size: 18px;
  color: var(--BLACK-333);
}

.wx_div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wx_img_div {
  background-image: url("../../../assets/img/contactUs_bg.png");
  padding: 1rem 1rem;
  box-sizing: border-box;
  margin: 2rem;
  width: 20%;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
}

.phone_div {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 1rem auto 0;
  font-size: 18px;
  color: #33488f;

  span {
    margin-left: 0.4rem;
    font-family: "alibaba", sans-serif;
  }
}

.sh_manifest_img {
  width: 90%;
  margin: auto;
  height: auto;
}

.consultBtn {
  letter-spacing: 2px;
  width: 20rem;
  padding: 1rem;
  line-height: 1.5;
  text-align: center;
  color: #fff;
  font-size: 20px;
  background: #e00f0f;
  opacity: 1;
  border: 1px solid #e30000;
  border-radius: 25px 0 25px 0;
  //margin-top: 20px;
  box-shadow: 10px 0 5px 0 rgba(240, 72, 72, .856);
}

.agreement_div {
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
}

.content_html {
  overflow: hidden;
  padding: 0 2rem;
  height: 100%;
  width: 100%;

  p {
    height: 100%;
  }

  /deep/ img {
    max-width: 100%;
    object-fit: contain;
    margin: auto;
    height: auto;
  }
}
</style>
